import React from "react";
import { Box, Card, Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HomeIcon from "@mui/icons-material/Home";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { useNavigate } from "react-router-dom";

function SuccessPage() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "1rem",
      }}
    >
      <Card
        sx={{
          padding: "2rem",
          borderRadius: "1rem",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          maxWidth: "400px",
          textAlign: "center",
          backgroundColor: "#fff",
        }}
      >
        <CheckCircleIcon sx={{ fontSize: "6rem", color: "#4caf50", marginBottom: "1rem" }} />
        <Typography variant="h5" gutterBottom>
          ¡Reserva completada con éxito!
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "1.5rem" }}>
          Tu reserva ha sido procesada correctamente. Te enviaremos los detalles por correo. ¡Revisa tu email!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<HomeIcon />}
          onClick={() => navigate("/")}
          sx={{ width: "100%", marginBottom: "1rem" }}
        >
          Volver al inicio
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<LoyaltyIcon />}
          onClick={() => navigate("/Catuel")}
          sx={{ width: "100%" }}
        >
          Beneficios con tu estadía
        </Button>
      </Card>
    </Box>
  );
}

export default SuccessPage;
